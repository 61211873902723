import {makeStyles, Theme} from '@material-ui/core/styles'
import {
	columnWidth,
	columnWidthUpLg,
	columnWidthUpSm,
	gapWidth,
	gapWidthUpLg,
	gapWidthUpSm
} from '@utils/constants'
import Styling from '@utils/types/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside Reference functional template
 */

const useStyles: Styling.US = () => {
	return makeStyles((theme: Theme) => ({
		reference: {
			overflowX: 'hidden',
			// Mobile
			padding: theme.spacing(175 / 8, 30 / 8),

			// Tablet
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(175 / 8, 75 / 8)
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				padding: theme.spacing(175 / 8, 150 / 8)
			}
		},
		grid: {
			display: 'grid',
			gridTemplateColumns: 'repeat(12, 1fr)',
			gridAutoRows: 'minmax(min-content, max-content)',

			// Mobile
			columnGap: theme.spacing(15 / 8),

			// Tablet
			[theme.breakpoints.up('sm')]: {
				columnGap: theme.spacing(15 / 8)
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				columnGap: theme.spacing(30 / 8)
			}
		},
		navigateBack: {
			display: 'flex',
			alignItems: 'center',
			gridColumn: 'span 6',
			gridRow: '1',
			color: theme.palette.text.primary,
			fontFamily: 'Poppins, sans-serif',
			fontSize: 16,
			letterSpacing: '-0.22px',
			lineHeight: '19px',
			marginBottom: theme.spacing(20 / 8),
			cursor: 'pointer',

			'& img': {
				height: 16,
				marginRight: theme.spacing(12 / 8)
			}
		},
		title: {
			gridRow: '2',
			color: theme.palette.text.primary,
			textTransform: 'uppercase',
			fontWeight: 300,

			// Mobile
			gridColumn: 'span 10',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: 'span 10'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: 'span 6'
			}
		},
		spotlightImage: {
			backgroundRepeat: 'no-repeat',
			backgroundSize: '100%',
			backgroundPosition: 'center',

			// Mobile
			height: '30vh',
			width: `calc(100vw - 30px - ${columnWidth} - ${gapWidth})`,
			transform: `translateX(calc(${columnWidth} + ${gapWidth}))`,

			// Tablet
			[theme.breakpoints.up('sm')]: {
				height: '30vh',
				width: `calc(100vw - 75px - ${columnWidthUpSm} - ${gapWidthUpSm})`,
				transform: `translateX(calc(${columnWidthUpSm} + ${gapWidthUpSm}))`
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				height: '60vh',
				width: `calc(100vw - 150px - ${columnWidthUpLg} - ${gapWidthUpLg})`,
				transform: `translateX(calc(${columnWidthUpLg} + ${gapWidthUpLg}))`
			},
			// XL Desktop
			[theme.breakpoints.up('xl')]: {
				height: '60vh',
				width: `calc(75vw - 150px - ${columnWidthUpLg} - ${gapWidthUpLg})`,
				transform: `translateX(calc(${columnWidthUpLg} + ${gapWidthUpLg}))`
			}
		},
		// New grid below spotlight
		subSpotlightTitle: {
			gridRow: '1',
			marginBottom: theme.spacing(16 / 8),
			color: theme.palette.text.primary,

			// Mobile
			gridColumn: '2 / span 9',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: '2 / span 9'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: '2 / span 6'
			}
		},
		body: {
			gridRow: '2',
			marginBottom: theme.spacing(16 / 8),
			color: theme.palette.text.primary,
			fontSize: 24,
			letterSpacing: '-0.33px',
			lineHeight: '28px',

			// Mobile
			gridColumn: '2 / span 11',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: '2 / span 10'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: '2 / span 6'
			}
		},
		bodySecondary: {
			gridRow: '3',
			color: theme.palette.text.secondary,
			fontSize: 16,
			letterSpacing: '-0.22px',
			lineHeight: '24px',

			// Mobile
			gridColumn: '3 / span 10',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: '3 / span 9'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: '3 / span 5'
			}
		},
		gallery: {
			display: 'grid',
			gridAutoFlow: 'dense',

			// Mobile
			gridTemplateColumns: `repeat(6, 1fr)`,
			gridAutoRows: `minmax(50px, 75px)`,
			gridGap: theme.spacing(15 / 8),

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridTemplateColumns: `repeat(6, 1fr)`,
				gridAutoRows: `minmax(50px, 100px)`,
				gridGap: theme.spacing(15 / 8)
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridTemplateColumns: `repeat(12, 1fr)`,
				gridAutoRows: `minmax(100px, 200px)`,
				gridGap: theme.spacing(30 / 8)
			}
		},
		galleryThumbnail: {
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: '100%',
			transition: 'background-position 1s ease-in-out',

			'& a': {
				display: 'block',
				width: '100%',
				height: '100%'
			}
		}
	}))()
}

export default useStyles
