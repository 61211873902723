import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Box, Typography, useMediaQuery, useTheme} from '@material-ui/core'
import {navigate} from 'gatsby-link'

import {CommonProps} from '@utils/types'
import {ContactComponent, MarginComponent} from '@components/index'

import useStyles from './style.hook'

/**
 * Reference
 */
interface ReferenceType {
	title: string
	subtitle: string
	hoverImage: {
		file: {
			url: string
		}
	}
	detailSpotlightImage: {
		title: string
		file: {
			url: string
		}
	}
	detailTitle: string
	detailBody: {
		detailBody: string
	}
	detailBodySecondary: {
		detailBodySecondary: string
	}
	galleryImages: {
		title: string
		file: {
			url: string
		}
	}[]
}
interface LocationWithState extends Location {
	state: {
		reference?: ReferenceType
	}
}

interface ReferenceProps extends CommonProps {
	location: LocationWithState
}

const Reference: React.FC<ReferenceProps> = ({location}) => {
	// Get styles from template-scoped styles hook
	const classes = useStyles()

	const theme = useTheme()

	const mediaUpSm = useMediaQuery(theme.breakpoints.up('sm'))
	const mediaUpLg = useMediaQuery(theme.breakpoints.up('lg'))

	const [reference, setReference] = useState<ReferenceType>(null)

	useEffect(() => {
		if (location && location.state && location.state.reference) {
			setReference(location.state.reference)
		}
	}, [location])

	const getRndInteger = (min: number, max: number) => {
		return Math.floor(Math.random() * (max - min + 1)) + min
	}

	const navigateBackOnClick = () => {
		navigate('/')
	}

	useEffect(() => {
		const lightbox = (global as any).lightbox
		if (lightbox) {
			lightbox.option({
				disableScrolling: true,
				wrapAround: true,
				positionFromTop: 50
			})
		}
	}, [])

	return (
		<Box id="reference-template" className={classes.reference}>
			{reference && (
				<>
					<Box className={classes.grid}>
						<Typography
							className={classes.navigateBack}
							variant="body1"
							onClick={navigateBackOnClick}
						>
							<img src="/images/reference/arrow-left.svg" />
							{`Keer terug`}
						</Typography>

						<Typography className={classes.title} variant="h1">
							{reference.title}
						</Typography>
					</Box>

					{mediaUpLg ? (
						<MarginComponent top={80} />
					) : mediaUpSm ? (
						<MarginComponent top={60} />
					) : (
						<MarginComponent top={60} />
					)}

					<Box
						className={classes.spotlightImage}
						style={{
							backgroundImage: `url(${reference.detailSpotlightImage.file.url})`
						}}
					/>

					{mediaUpLg ? (
						<MarginComponent bottom={120} />
					) : mediaUpSm ? (
						<MarginComponent bottom={120} />
					) : (
						<MarginComponent bottom={60} />
					)}

					<Box className={classes.grid}>
						<Typography className={classes.subSpotlightTitle} variant="h1">
							{reference.detailTitle}
						</Typography>

						<Typography className={classes.body} variant="body1">
							{reference.detailBody.detailBody}
						</Typography>
						<Typography className={classes.bodySecondary} variant="body1">
							{reference.detailBodySecondary.detailBodySecondary}
						</Typography>
					</Box>

					{mediaUpLg ? (
						<MarginComponent top={120} />
					) : mediaUpSm ? (
						<MarginComponent top={120} />
					) : (
						<MarginComponent top={60} />
					)}

					<Box className={classes.gallery}>
						{reference.galleryImages.map((image, i) => {
							const gridColumnEnd = `span ${getRndInteger(2, 4)}`
							const gridRowEnd = `span ${getRndInteger(1, 2)}`

							return (
								<Box
									key={`img-${i}`}
									className={classes.galleryThumbnail}
									style={{
										backgroundImage: `url(${image.file.url})`,
										gridColumnEnd,
										gridRowEnd
									}}
								>
									<a href={image.file.url} data-lightbox="gallery" />
								</Box>
							)
						})}
					</Box>
				</>
			)}
			{mediaUpLg ? (
				<MarginComponent bottom={220} />
			) : mediaUpSm ? (
				<MarginComponent bottom={110} />
			) : (
				<MarginComponent bottom={110} />
			)}

			<ContactComponent />
		</Box>
	)
}

Reference.propTypes = {
	location: PropTypes.any
}

export default Reference
